<template>


  <div class="recomendation-line" v-if="recomendation">
    <router-link :to="'/rekomendacje/'+recomendation.url" class="recomendation-line__thumb"
                 :style="{ backgroundImage: `url(${recomendation.block_thumb})` }"></router-link>
    <div class="recomendation-line__info">
      <h3 class="recomendation-line__title">
        <router-link :to="'/rekomendacje/'+recomendation.url">
          {{ recomendation.title }}
        </router-link>
      </h3>

      <div class="recomendation-line__stars">
        <Star :recomendation="recomendation"/>
      </div>

      <br>
      <ul class="meta">
        <li class="meta__item" v-if="recomendation.audio_time">
          <i class="material-icons">access_time</i>
          {{ recomendation.audio_time }}
        </li>

        <li class="meta__item">
          <i class="material-icons">calendar_today</i>
          {{ date }}
        </li>

        <li class="meta__item">
          <ul class="tag-list">
            <li class="tag-list__item" v-for="label in recomendation.labels">{{ label.name }}</li>
          </ul>
        </li>
      </ul>

      <br v-if="recomendation.users && recomendation.users.lenght == 0">

      <p class="recomendation-line__text" v-html="recomendation.text" v-if="recomendation.text && recomendation.text.lenght > 4"></p>

    </div>
    <ul class="options">

      <li class="options__item" v-if="recomendation.audio_url">

        <a :href="audioUrl"
           :data-name="recomendation.name"
           :data-thumb="recomendation.block_thumb"
           :data-url="audioUrl"
           :id="'audio'+recomendation.id"
           class="audio-btn"

           v-on:click="playAudio"
        >
          <i class="material-icons">play_arrow</i>
        </a>

      </li>

      <!--      <li class="options__item options__item-text-btn recomendation-line__text-btn" v-if="recomendation.text"></li>-->
    </ul>
  </div>

</template>

<script>
import axios from "@/axios";
import CoreDate from "@/helpers/date";
import Star from "@/views/Recomendation/Part/Star.vue";

export default {
  name: "RecomendationLine",
  components: {Star},
  props: {'recomendation': null},

  methods: {

    playAudio: function (e) {

      e.preventDefault();

      let audioPlayer = this.$root.$refs.audioPlayer;
      let recomendation = this.recomendation;

      audioPlayer.set(recomendation.audio_url, recomendation.name, recomendation.block_thumb);
      audioPlayer.play();
      audioPlayer.show();

    },
  },
  computed: {
    audioUrl() {

      let recomendation = this.recomendation;

      let ret;
      if (!ret && recomendation.audio_url_force) {
        ret = recomendation.audio_url_force;
      }
      if (!ret && recomendation.audio_url) {
        ret = recomendation.audio_url;
      }

      return ret;
    },
    date() {
      return CoreDate.show(this.recomendation.date)
    }
  },

  data() {
    return {}
  },
  created() {


    // let domain = "audycje"
    // let domain_new = "recomendationy"


    // this.recomendation.url = domain_new + this.recomendation.url.substr(domain.length)

  }

}
</script>

<style scoped lang="scss">
.recomendation-line {
  position: relative;

  margin-bottom: 18px;
  min-height: 90px;

  border: 1px solid #eee;
  padding: 8px 8px 8px 134px;

  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;

  & .meta {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;

    color: #aaa;

  }

  & .audio-btn {

    width: 40px;
    height: 40px;

    background-color: #00003f;
    color: #fff;

    text-align: center;

    display: inline-block;
    border-radius: 50%;

    i {
      line-height: 38px;

    }

  }
}


.recomendation-line__thumb {

  width: 120px;

  left: 0;
  top: 0;
  bottom: 0;

  display: block;

  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.recomendation-line__title {

  font-weight: 700;

  font-size: 16px;

  margin: 12px 0 0;
  padding: 0;

  max-height: 37px !important;
  width: calc(100% - 60px);

  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: #000;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}
.recomendation-line__stars {
  margin-top: 6px;

}

.recomendation-line__text {
  padding: 0 164px 24px 0;
  margin: 0;
  line-height: 1.8em;

  font-family: "Poppins", sans-serif;

  display: inline-block;

  display: none;
}

.recomendation-line__text-open {
  display: inline-block;
}

.recomendation-line .options {
  position: absolute;

  right: 16px;

  top: 50%;

  margin: 0;

  transform: translateY(-50%);
}


.recomendation-line:hover {
  background-color: #fff;
  color: #000;
}


.recomendation-line:hover .recomendation-line__text-btn:before {

  border-left: 2px solid #000 !important;
  border-top: 2px solid #000 !important;
}

.recomendation-line__text-btn {

  margin: 0;
  padding: 0;
  display: inline-block;
  width: 42px;
  height: 42px;

  cursor: pointer;
}

.recomendation-line__text-btn:before {
  position: absolute;
  content: '';

  top: 30px;
  right: 16px;


  width: 14px;
  height: 14px;

  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  transition: 0.5s transform;
  will-change: transform;
  transform: rotate(
          -135deg
  ) translateY(6px) translateX(6px);
}

.recomendation-line__text-btn.recomendation-line__text-btn-open:before {
  transform: rotate(
          45deg
  );
}

@media screen and (max-width: 768px) {

  .recomendation-line {
    height: auto !important;
    padding: 8px;


  }

  .recomendation-line__info {
    width: 100%;
    display: inline-block;
  }

  .recomendation-line__thumb {
    padding-bottom: 67%;
    height: 0;

    width: calc(100% - 0px);
    position: initial;
  }

  .recomendation-line__title {
    width: calc(100% - 0px);

    padding: 8px 0;
    height: auto !important;

    font-size: 18px;

    max-height: none !important;


  }

  .recomendation-line .options {
    top: auto;
    bottom: 12px;

    display: none;
  }
}

.stars {
  position: relative;
  float: left;
  font-size: 14px;

  display: inline-block;
  //height: 1em;
  //line-height: 1em;

  &:before {
    content: "\2B24\2B24\2B24\2B24\2B24";
    float: left;
    z-index: 1;
    position: relative;
    color: #eee;
    letter-spacing: 2px;
  }
  & .stars__percent {

    position: absolute;
    left: 0;
    //right: 0;
    top: 0;
    bottom: 0;

    float: left;

    color: #1d4cff;
    letter-spacing: 2px;
    z-index: 2;
    overflow: hidden;
    //text-shadow: -1px -1px 0 #1d4cff, 1px -1px 0 #1d4cff, -1px 1px 0 #1d4cff, 1px 1px 0 #1d4cff;

  }
}




</style>

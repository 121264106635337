<template>

  <div class="sidenav" :class="{ 'sidenav-open': is_open }">
    <router-link to="/" tag="a" @click.prevent="$root.$refs.menuMobile.close()">
      <img src="@/assets/logo.png" alt="Radio Kraków Kultura" class="sidenav-logo">
    </router-link>
    <ul class="sidenav-menu">
      <MenuPosition />
    </ul>
  </div>
  <div class="sidenav-overlay" v-if="is_open" @click="close"></div>
</template>

<script>
import axios from "@/axios2";
import MenuPosition from "./MenuPosition";

export default {
  name: "MenuDynamic",
  components: {MenuPosition},
  data() {
    return {
      poss: null,
      count: 0,
      is_open: false,
    }
  },
  methods: {
    open: function () {

      this.is_open = true;

      console.log('Otweram Menu mobilne')

    },
    close: function () {

      this.is_open = false;

    },
  },

  created: function () {
    let self = this;
    //
    // axios
    //     .get('sitemenu/poss', {params: {code: 'top'}})
    //     .then(response => {
    //
    //       let data = response.data;
    //
    //       this.poss = data.data;
    //       this.count = this.poss.length;
    //
    //     })
    //     .catch(e => {
    //       console.log(e)
    //     });


  }
}
</script>

<style scoped lang="scss">
.sidenav {

  transform: translateX(-105%);
  transition: .3s;

  &-logo {

    padding: 20px;
    max-width: 200px;

  }

  &-menu {

    text-align: left;

  }
  &-open {
    transform: translateX(0%) !important;

  }

  &-overlay {

    position: fixed;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    opacity: 1;
    display: block;
  }
}
</style>
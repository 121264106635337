<template>
  <div class="radio_player" v-show="this.is_show">
    <div class="radio_player__close close-x close-x-small close-x-white " @click="close()"></div>
    <div class="radio_player__thumb" :style="{ backgroundImage: `url(${family_thumb})` }"></div>
    <div class="radio_player__info">
      <div class="radio_player__name">{{ family_name }}</div>
      <div class="radio_player__composer">{{ song_composer }} -</div>
      <div class="radio_player__song">{{ song_name }}</div>
      <div class="radio_player__artist" v-if="song_composer !== song_artist"> - {{ song_artist }}</div>
    </div>
    <div class="radio_player__play" id="radio_player__play" v-on:click="play" v-bind:class="{ played: is_played }">
      <i class="material-icons play">play_arrow</i>
      <i class="material-icons pause">pause</i>
    </div>

  </div>
</template>

<script>
import axios2 from "@/axios2";
import {mapActions, mapState} from 'vuex'

export default {
  name: 'RadioPlayer',
  props: {
    msg: String
  },
  data() {
    return {
      is_show: false,
      is_played: false,
      // url: "http://stream2.nadaje.com:13332/offradiokrakow",
      // url: "https://stream2.nadaje.com:13333/offradiokrakow",
      url: "https://stream4.nadaje.com:9777/RKKultura",
      audio: null,
      family_name: null,
      family_thumb: null,
      song_name: null,
      song_artist: null,
      song_composer: null,
    }
  },
  created: function () {
    this.refreshRoadmap();
    this.refreshSong();
  },
  computed: {
    ...mapState(['radio_play'])
  },
  methods: {

    ...mapActions(['radioPlay', 'radioStop']),
    play: function () {

      if (this.is_played) {
        this.stop();
      } else {

        if (!this.audio) {
          this.audio = new Audio(this.url);
        }

        this.audio.play();
        this.is_played = true;
        this.radioPlay();

      }

      this.$root.$refs.audioPlayer.stop()
      this.$root.$refs.audioPlayer.hide()
    },
    stop: function () {

      let audio = this.audio;

      if (audio) {

        audio.pause();
        audio.src = audio.src;
      }

      this.radioStop();

      this.is_played = false;

    },
    open: function () {
      this.is_show = true;

      if (!this.is_played)
        this.play()

    },
    close: function () {
      this.is_show = false;

      if (this.is_played)
        this.stop()

    },
    hide: function () {
      this.is_show = false;

      if (this.is_played)
        this.stop()

    },

    viewToggle: function () {

      if (!this.is_show) {
        this.open();
      } else {
        this.close();
      }

    },

    refreshRoadmap: function () {


      let self = this;

      axios2
          .get('articleroadmap/now')
          .then(response => {

                let data = response.data;

                let now = data.now;


                let domain = "https://off.radiokrakow.pl/api/"
                let domain_new = "https://off.radiokrakow.pl/"

                // now.thumb = domain_new + now.thumb.substr(domain.length)

                this.family_name = now.name;
                this.family_thumb = now.thumb;

                setTimeout(function () {
                  self.refreshRoadmap();
                }, 60000);

              }
          );

    },
    refreshSong: function () {


      let self = this;

      axios2
          .get('index/song')
          .then(response => {

                let data = response.data;

                this.song_name = data.song;
                this.song_artist = data.artist;
                this.song_composer = data.composer;

                setTimeout(function () {
                  self.refreshSong();
                }, 60000);

              }
          );

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.close-x {

  position: absolute;
  top: 0px;
  right: 5px;

  color: #ffffff;

  opacity: .5;

  height: 30px;
  width: 30px;

  border: none;
  background: none;
  outline: none;

  z-index: 4;

  cursor: pointer;
}

.close-x:focus {
  outline: none;

}

.close-x:before,
.close-x:after {

  position: absolute;

  top: 50%;
  left: 0;

  display: block;
  content: "";

  height: 1px;
  width: 100%;

  background: #000;
}

.close-x-white:before,
.close-x-white:after {
  background: #fff;
}

.close-x:before {
  transform: rotate(-45deg);
}

.close-x:after {
  transform: rotate(-135deg);
}

.close-x:hover {
  opacity: 1;
}

.close-x.close-x-small {
  width: 20px;
  height: 20px;
}

.radio_player {

  -moz-box-shadow: 0 0 20px #00000055;
  -webkit-box-shadow: 0 0 20px #00000055;
  box-shadow: 0 0 20px #00000055;
  //position: fixed;
  position: relative;
  left: 0px;
  bottom: -1px;
  right: 0px;

  height: 70px;

  padding-left: 96px;

  z-index: 20000;

  /*border-top: 1px solid white;*/

  background-color: #1d4bfe;

  min-width: 100%;

  width: 100%;

  //display: block!important;
  @media only screen and (max-width: 768px) {
    padding-left: 76px;

  }
}

.radio_player.radio_player-open {
  display: block !important;
}

.radio_player__close {

  top: 70px !important;
  right: 0px !important;
  background-color: #0e257c !important;
  opacity: 1 !important;
}

.radio_player__thumb {

  width: 60px;
  height: 50px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;


  left: 8px;
  top: 8px;
}

.radio_player__info {
  width: calc(100% - 50px);
}

.radio_player__name {
  font-weight: 700;
  font-size: 18px;
  padding-top: 8px;

  color: #fff;
}


.radio_player__composer {
  display: inline-block;
  opacity: .9;
  color: #fff;
  margin-right: 12px;
  @media only screen and (max-width: 768px) {

    display: none;
  }
}

.radio_player__song {
  display: inline-block;
  margin-right: 12px;
  opacity: .7;
  color: #fff;
}

.radio_player__artist {
  display: inline-block;
  opacity: .9;
  color: #fff;
  @media only screen and (max-width: 768px) {

    display: none;
  }

}

.radio_player__play {
  position: absolute;

  right: 0;
  transform: translateX(-50%);

  top: 12px;
  width: 48px;
  height: 48px;

  border-radius: 50%;

  background-color: #fff;

  text-align: center;

  line-height: 38px;

  cursor: pointer;

  & .play,
  & .pause,
  {
    height: 40px;
    line-height: 48px;
  }

  @media only screen and (max-width: 768px) {
    right: -16px;
  }
}

.radio_player__play .pause {
  display: none;
}

.radio_player__play .play {
  display: block;
}

.radio_player__play.played .pause {
  display: block;
}

.radio_player__play.played .play {
  display: none;
}
</style>

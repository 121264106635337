<template>


    <RadioPlayer ref="radioPlayer"/>
    <AudioPlayer ref="audioPlayer"/>
  <header>
    <div class="container">

      <div class="row">
        <div class="col s6 m12">

          <menu>

            <router-link to="/" class="brand-logo" tag="a">
              <img src="@/assets/logo-kobalt.png" alt="Radio Kraków">
            </router-link>

            <MenuTop/>
          </menu>
        </div>
        <div class="col s6 hide-on-large-only hide-on-extra-large-only">

          <ul class="menu-btns">
            <li class="menu-btns__item menu-btns__item-play">
              <a href="https://www.radiokrakow.pl/player" class="menu-btns__link menu-btns__link-play"  v-on:click="radioPlayerClick">

                <img src="@/assets/images/play.png" alt="" style="height: 26px;" class="play" v-if="!this.radio_play">

                <i class="material-icons" v-if="this.radio_play">pause</i>
              </a>
            </li>
            <li class="menu-btns__item">
              <a href="!#" data-target="mobile-demo" class="sidenav-trigger white-text menu-btns__link"
                 @click="openMobileMenu">
                <i class="material-icons">menu</i>
              </a>

            </li>
          </ul>
        </div>
      </div>

    </div>
  </header>

  <router-view/>

  <MenuDynamic ref="menuMobile"/>

  <Loader/>

  <footer>

    <div class="container">

      <div class="row">
        <div class="col s12"><b></b>
          <ul class="right list-social">
            <li class="list-social-item"><a href="https://open.spotify.com/user/pjwfvnrnrymjtiiqqvyoxvdgf" target="_blank">
              <i class="fab fa-spotify"></i>
            </a></li>
            <li class="list-social-item"><a href="https://twitter.com/RadioKrakow" target="_blank" style="display: none;">
              <i class="fab fa-twitter"></i>
            </a></li>
            <li class="list-social-item"><a href="https://www.facebook.com/RadioKrakowKultura" target="_blank">
              <i class="fab fa-facebook-f"></i></a></li>
            <li class="list-social-item"><a href="https://www.youtube.com/channel/UCRYJv0ixGJpwFd0ba6bIWtg" style="display: none;"
                                            target="_blank">
              <i class="fab fa-youtube"></i></a></li>
            <li class="list-social-item"><a href="https://www.instagram.com/radiokrakowkultura/" target="_blank">

              <i class="fab fa-instagram"></i>
            </a></li>
          </ul>
          <ul class="left list-line m-0">


            <MenuPosition type="full"/>

            <li>
              <router-link to="/partnerzy"  @click.prevent="$root.$refs.menuMobile.close()">
                Partnerzy
              </router-link>
            </li>
            <li>
              <router-link to="/contact"  @click.prevent="$root.$refs.menuMobile.close()">
                Kontakt
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <hr>
        </div>

      </div>

      <div class="row">
        <div class="col s12 center-align">
          <a href="/misja">
            <img src="@/assets/images/fala.png"
                 alt="Radio Kraków" style="width: 200px">
            <!--                        <img src="--><!--" alt="Radio Kraków"/>-->
          </a>
        </div>
      </div>
    </div>


  </footer>

</template>

<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


.menu-btns {

  text-align: right;

  display: block;

  align-content: flex-end;
  align-items: end;

  margin-top: 13px;

  &__item {
    display: inline-block;
    padding: 16px 20px;
    float: right;

    &-play{
      //padding: 0;
      position: relative;
      width: 60px;
      min-width: 60px;
      display: inline-block;
      background-color: rgba(0,0,0,.6);
line-height: 56px;

      text-align: center;

      max-height: 60px;
      padding: 8px 18px;


      border-radius: 6px;

      img{
        margin: 8px 0;
      }
      i{
        color: white;
      }
    }

  }
}


footer {
  //color: #fff;
  //background-color: #00003e;

  padding: 64px 0 0;
  /*margin-top: 200px;*/

  //-moz-box-shadow: 0 0 20px #00000055;
  //-webkit-box-shadow: 0 0 20px #00000055;
  //box-shadow: 0 0 20px #00000055;

  position: relative;

  z-index: 2;

  float: left;
  width: 100%;
}

footer hr {

  border: none;
  width: 100%;
  height: 1px;
  background-color: #00000055;
  background-color: #1d4cff;

}

footer .footer-dark {
  background-color: #00000033;
  padding: 24px 0;
  margin-top: 48px;
}


.list-line {
  margin: 0 12px;
  padding: 0;

  list-style: none;

  @media screen and (max-width: 720px){

    text-align: center;
  }

}

.list-line li {

  margin: 0 0 12px;
  padding: 0 12px;

  display: inline-block;

  //color: white;

}

.list-line li a {
  color: inherit;
  font-weight: normal;
}

.list-social {
  margin: -8px 0;
  padding: 0;

  list-style: none;

  @media screen and (max-width: 720px){

    width: 100%;

    text-align: center;

    margin-bottom: 24px;

  }
}

.list-social li {

  margin: 0 5px;
  padding: 0;

  display: inline-block;

  //color: white;

}

.list-social li a {
  color: inherit;
  font-weight: normal;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #1f4568;;
  display: inline-block;

  text-align: center;
  line-height: 36px;
}

.list-social li a:hover {
  background: #fff;;
  color: #1f4568;

}

.list-icon {
}

.list-icon li {
  height: 32px;
  line-height: 24px;
  width: 100%;
  display: inline-block;

  position: relative;
  padding-left: 32px;
}

.list-icon li i,
.list-icon li span,
.list-icon li b {
  float: left;
  height: 24px;
}

.list-icon li i {
  position: absolute;

  left: 0;
  top: 0;

  font-size: 20px;
  color: #1d4cff;
}

.list-icon li span {
  /*text-decoration: underline;*/
}

.list-icon li b {
  margin-left: 6px;
}

</style>
<script>
import MenuTop from "./components/MenuTop";
import MenuDynamic from "./components/MenuDynamic";


import '@/assets/css/fontawesome.css';
import RadioPlayer from "./components/RadioPlayer";
import AudioPlayer from "./components/AudioPlayer";
import MenuPosition from "./components/MenuPosition";
import Loader from "./components/Loader";
import {mapState} from "vuex";

export default {
  components: {Loader, MenuPosition, AudioPlayer, RadioPlayer, MenuDynamic, MenuTop},

  computed: {
    ...mapState(['radio_play']),
  },
  methods: {
    radioPlayerClick(e) {

      e.preventDefault();

      this.$root.$refs.radioPlayer.viewToggle()

    },
    openMobileMenu(e) {

      e.preventDefault();

      this.$root.$refs.menuMobile.open();
    },
    metaSet: function (name, text, image = null) {
      document.title = name;
      document.querySelector('meta[property="og:title"]').content = name;
      document.querySelector('meta[property="og:description"]').content = text;
      document.querySelector('meta[property="og:image"]').content = image;


    }
  }
}
</script>
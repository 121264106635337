<template>
  <section class="article-list" v-if="articles && articles.length > 0">
    <div class="row mb-0">
      <div class="col s12">
        <div class="title-line mb-4">

          <h2 class="big-title">
            <router-link to="/podcasty" title="Rekomendacje" style="color: #000000">
              Najnowsze podcasty
            </router-link>
          </h2>

        </div>
      </div>
    </div>
    <div class="article-user-section">

      <div class="row mb-0">
        <div class="col s12"
             :class="{
              l6: in_row === 2
             }"
             v-for="article in articles">
          <PodcastLine :article="article"/>

        </div>
      </div>



    </div>
  </section>
</template>

<script>
import CoreArray from "@/helpers/array.js";
import axios from "@/axios2";

import PodcastLine from "@/views/Audio/Part/PodcastLine";
import {Carousel, Navigation, Slide} from "vue3-carousel";
import ComponentListStateMachine from "../../../Extends/ComponentListStateMachine";

export default {
  name: "PodcastNewest",
  extends: ComponentListStateMachine,
  props: ['id_user','in_row'],
  components: {
    PodcastLine,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      articles: null,
    }
  },

  created: function () {

    this.stateUpdate('sendInfo');

    axios
        .get('article/get', {
          params: {
            is_show: true,
            count: 6,
            type: 'broadcast',
            scope: 'title,block_thumb,thumb,date,url,audio,audio_url_force,'
          }
        })
        .then(response => {

          let domain = "https://off.radiokrakow.pl/api/"
          let domain_new = "https://off.radiokrakow.pl/"

          let data = response.data;

          let articles = data.data;

          articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');
          // articles = CoreArray.changeDomain(articles, 'block_thumb', domain, domain_new);
          // articles = CoreArray.changeDomain(articles, 'audio_url', domain, domain_new);

          this.articles = articles;

          this.stateUpdate('sendSuccess');

        })
        .catch(e => {
          console.log(e)

          this.stateUpdate('sendWrong');
        })

  }
}
</script>

<style>


</style>

<template>
  <div class="recommendation-block article-block-half">

    <ul class="options">
      <li class="options__item" style="display: none;">
      </li>
    </ul>

    <router-link :to="'/rekomendacje/'+recommendation.url" class="recommendation-block__bg"
                 :style="{ backgroundImage: `url(${recommendation.thumb})` }"></router-link>

    <div class="recommendation-block__info">
      <h2 class="recommendation-block__title">
        <router-link :to="'/rekomendacje/'+recommendation.url">
          {{ recommendation.title }}
        </router-link>
      </h2>
      <Star :recomendation="recommendation" class="mb-1"/>



    </div>


  </div>
</template>

<script>

import Star from "@/views/Recomendation/Part/Star.vue";

export default {
  name: "RecommendationBlock",
  components: {Star},
  props: {
    recommendation: {
      default: null
    }
  },
}
</script>

<style scoped lang="scss">

.recommendation-block {
  position: relative;
  margin-bottom: 24px;


  transition: .3s all;
}

.recommendation-block__bg {
  width: 100%;
  height: 0;
  padding-bottom: 67%;

  display: inline-block;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.recommendation-block__archive {
  position: absolute;

  left: 8px;
  top: 8px;

  background-color: #000;

  padding: 4px 16px;
}

.recommendation-block__info {
  height: auto;
  position: absolute;
  bottom: 0;
  padding: 20px;

}

.recommendation-block__title {

  display: inline-block;
  text-transform: uppercase;
  line-height: 20px;

  //overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: none;
  margin: 0 0 8px;
  width: 100%;
  //height: 34px;

  a {
    color: white;
    text-shadow: 1px 1px 10px #000;
  }
}

.recommendation-block__title a {
  line-height: 20px;
  font-size: 16px !important;
  font-weight: 700;
  //white-space: nowrap;
}

.recommendation-block__text {

}

.recommendation-block__roadmap {
  color: #4c4c4c;
}

.recommendation-block .user-list {
  margin-bottom: 16px;
}


.recommendation-block .options {
  position: absolute;
  right: 16px;
  top: 16px;
}


.recommendation-block:hover {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 768px) {
  .recommendation-block__title {
    text-overflow: ellipsis;
    min-height: 34px;
    height: auto;
    line-height: 12px;
  }
  .recommendation-block__title a {

    line-height: 12px;
    white-space: unset;
  }

  .recommendation-block .options {
    display: none;
  }

  .recommendation-block__info .tag-list {

    margin-top: 12px;
  }

  .recommendation-block__info {
    /*min-height: 70px;*/
    height: auto;
  }
}
</style>

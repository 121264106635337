<template>

  <main class="contact">


      <div class="row">
        <div class="col s12">
          <h1 class="big-title">
            <router-link to="/audycje" title="Audycje" style="color: #000000">
            Audycje
            </router-link>
          </h1>
        </div>
      </div>

      <div class="article-family-section" :class="{loading: stateCompare('loading')}">
        <carousel v-if="familys && familys.length > 0" :settings="settings" :breakpoints="breakpoints">
          <slide v-for="family in familys" :key="family.id">
            <BroadcastBlock :family="family"/>
          </slide>

          <template #addons>
            <Navigation/>
          </template>
        </carousel>

      </div>


  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import PeopleLine from "@/views/People/Part/Line.vue";
import CoreArray from "@/helpers/array.js";
import {Carousel, Navigation, Slide} from "vue3-carousel";
import axios from "@/axios2";
import BroadcastBlock from "./BroadcastBlock";
import ComponentListStateMachine from "../../../Extends/ComponentListStateMachine";

export default {
  name: "BroadcastOnMain",
  extends: ComponentListStateMachine,
  components: {
    BroadcastBlock,
    Carousel,
    Slide,
    Navigation,
    PeopleLine,
  },
  data() {
    return {
      'count': null,
      'familys': null,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1224: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },

    }
  },
  methods: {
    refresh: function () {

      this.stateUpdate('sendInfo');

      axios
          .get('articlefamily/get', {
            params: {
              sort: 'title',
              count: 999999,
              scope: 'title,url,thumb,familys',
              familys_scope: 'name,account_type,firstname,lastname,avatar_url,url_full'
            }

          })
          .then(response => {

            let familys = response.data.data;
            this.familys = familys;

            // let from = "https://off.radiokrakow.pl/api/"
            // let to = "https://off.radiokrakow.pl/"
            // this.familys = CoreArray.changeDomain(familys,'thumb',from,to);

            this.stateUpdate('sendSuccess');

          })
          .catch(e => {

            this.stateUpdate('sendWrong');
          })

    }
  },
  watch: {
    page: function (val) {
      this.refresh();
    },
  },
  created() {
    this.refresh();
  }
}
</script>


<style>


.article-family-section .carousel {
  height: auto;
  overflow: visible !important;
}

.article-family-section .carousel__viewport {
  overflow-y: visible !important;

}

.article-family-section .carousel__slide {
  text-align: left;
  padding: 0 12px;
}

.article-family-section .carousel__slide .family-block {
  width: 100%;
}

.article-family-section .carousel__prev,
.article-family-section .carousel__next {

  top: -33px !important;
  background-color: transparent;
  position: absolute;
  color: #1d4bfe;

  display: block;
  left: auto;

  /*background-color: red!important;*/

  z-index: 200000;
}

.article-family-section .carousel__prev {
  right: 45px !important;
}

.article-family-section .carousel__next {
  right: 25px !important;
}

</style>

<template>


  <div class="podcast-line" v-if="article">
    <router-link :to="'/'+article.url" class="podcast-line__thumb"
                 :style="{ backgroundImage: `url(${article.block_thumb})` }"></router-link>
    <div class="podcast-line__info">
      <h3 class="podcast-line__title">
        <router-link :to="'/'+article.url">
          {{ article.title }}
        </router-link>
      </h3>
      <ul class="meta">
        <li class="meta__item" v-if="article.audio_time">
          <i class="material-icons">access_time</i>
          {{ article.audio_time }}
        </li>

        <li class="meta__item">
          <i class="material-icons">calendar_today</i>
          {{ date }}
        </li>

        <li class="meta__item">
          <ul class="tag-list">
            <li class="tag-list__item" v-for="label in article.labels">{{ label.name }}</li>
          </ul>
        </li>
      </ul>

      <br v-if="article.users && article.users.lenght == 0">

      <p class="podcast-line__text" v-html="article.text" v-if="article.text && article.text.lenght > 4"></p>

    </div>
    <ul class="options">

      <li class="options__item" v-if="article.audio_url">

        <a :href="audioUrl"
           :data-name="article.name"
           :data-thumb="article.block_thumb"
           :data-url="audioUrl"
           :id="'audio'+article.id"
           class="audio-btn"

           v-on:click="playAudio"
        >
          <i class="material-icons">play_arrow</i>
        </a>

      </li>

      <!--      <li class="options__item options__item-text-btn podcast-line__text-btn" v-if="article.text"></li>-->
    </ul>
  </div>

</template>

<script>
import axios from "@/axios";
import CoreDate from "@/helpers/date";

export default {
  name: "PodcastLine",
  components: {},
  props: {'article': null},

  methods: {

    playAudio: function (e) {

      e.preventDefault();

      let audioPlayer = this.$root.$refs.audioPlayer;
      let article = this.article;

      audioPlayer.set(article.audio_url, article.name, article.block_thumb);
      audioPlayer.play();
      audioPlayer.show();

    },
  },
  computed: {
    audioUrl() {

      let article = this.article;

      let ret;
      if (!ret && article.audio_url_force) {
        ret = article.audio_url_force;
      }
      if (!ret && article.audio_url) {
        ret = article.audio_url;
      }

      return ret;
    },
    date() {
      return CoreDate.show(this.article.date)
    }
  },

  data() {
    return {}
  },
  created() {


    // let domain = "audycje"
    // let domain_new = "podcasty"


    // this.article.url = domain_new + this.article.url.substr(domain.length)

  }

}
</script>

<style scoped lang="scss">
.podcast-line {
  position: relative;

  margin-bottom: 18px;
  min-height: 90px;

  border: 1px solid #eee;
  padding: 8px 8px 8px 134px;

  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;

  & .meta {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;

    color: #aaa;

  }

  & .audio-btn {

    width: 40px;
    height: 40px;

    background-color: #00003f;
    color: #fff;

    text-align: center;

    display: inline-block;
    border-radius: 50%;

    i {
      line-height: 38px;

    }

  }
}


.podcast-line__thumb {

  width: 120px;

  left: 0;
  top: 0;
  bottom: 0;

  display: block;

  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.podcast-line__title {

  font-weight: 700;

  font-size: 16px;

  margin: 12px 0 0;
  padding: 0;

  max-height: 37px !important;
  width: calc(100% - 60px);

  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: #000;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.podcast-line__text {
  padding: 0 164px 24px 0;
  margin: 0;
  line-height: 1.8em;

  font-family: "Poppins", sans-serif;

  display: inline-block;

  display: none;
}

.podcast-line__text-open {
  display: inline-block;
}

.podcast-line .options {
  position: absolute;

  right: 16px;

  top: 50%;

  margin: 0;

  transform: translateY(-50%);
}


.podcast-line:hover {
  background-color: #fff;
  color: #000;
}


.podcast-line:hover .podcast-line__text-btn:before {

  border-left: 2px solid #000 !important;
  border-top: 2px solid #000 !important;
}

.podcast-line__text-btn {

  margin: 0;
  padding: 0;
  display: inline-block;
  width: 42px;
  height: 42px;

  cursor: pointer;
}

.podcast-line__text-btn:before {
  position: absolute;
  content: '';

  top: 30px;
  right: 16px;


  width: 14px;
  height: 14px;

  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  transition: 0.5s transform;
  will-change: transform;
  transform: rotate(
          -135deg
  ) translateY(6px) translateX(6px);
}

.podcast-line__text-btn.podcast-line__text-btn-open:before {
  transform: rotate(
          45deg
  );
}

@media screen and (max-width: 768px) {

  .podcast-line {
    height: auto !important;
    padding: 8px;


  }

  .podcast-line__info {
    width: 100%;
    display: inline-block;
  }

  .podcast-line__thumb {
    padding-bottom: 67%;
    height: 0;

    width: calc(100% - 0px);
    position: initial;
  }

  .podcast-line__title {
    width: calc(100% - 0px);

    padding: 8px 0;
    height: auto !important;

    font-size: 18px;

    max-height: none !important;


  }

  .podcast-line .options {
    top: auto;
    bottom: 12px;

    display: none;
  }
}

</style>
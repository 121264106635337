<template>
  <div class="page-loader" v-if="!isloaded">

    <img src="@/assets/logo-white.png" class="logo" alt="">
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isloaded: false
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isloaded = true;
      }
    }
  },
}
</script>

<style lang="css" scoped>
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00003f;
  z-index: 1999;

  transition: 2s;

  display: none;
}

.logo {
  width: 300px;
  animation: pulse-loader 1.5s infinite;

}


@keyframes pulse-loader {
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-heartbeat {
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}


</style>

<template>

  <div class="roadmap-now" v-if="is_show">


    <div class="roadmap-now__thumb" v-if="family_thumb">
      <div class="roadmap-now__thumb-img" :style="{ backgroundImage: `url(${family_thumb})` }"></div>
    </div>
    <div class="roadmap-now__info" >

      <div class="roadmap-now__family">{{ family }}</div>
      <span class="roadmap-now__song" :title="song_name +' - '+ song_artist">{{ song_name }} {{ song_artist }}</span>
    </div>
    <div class="roadmap-now__play" @click="radioPlayerClick">
      <img src="@/assets/images/play.png" alt="" class="play" v-if="!this.radio_play">

      <i class="material-icons" v-if="this.radio_play">pause</i>
    </div>

  </div>

</template>

<script>

import axios2 from "@/axios2";
import CoreArray from "@/helpers/array.js";
import { mapState} from "vuex";

export default {
  name: "RoadmapNow",
  data() {
    return {
      is_show: true,
      family: null,
      family_thumb: null,
      family_link: null,
      song_name: null,
      song_artist: null,
    };
  },
  computed: {
    ...mapState(['radio_play']),
  },
  methods: {
    radioPlayerClick(e) {

      e.preventDefault();

      this.$root.$refs.radioPlayer.viewToggle()

    },
    refresh: function () {

      let self = this;

      axios2
          .get('articleroadmap/now')
          .then(response => {

                let data = response.data;
                let now = data.now;

                let from = "https://off.radiokrakow.pl/api/"
                let to = "https://off.radiokrakow.pl/"
                // let thumb_arr = CoreArray.changeDomain([now], 'thumb', from, to);

                // now = thumb_arr[0];


                this.family = now.name;
                this.family_link = now.obj ? now.obj.slug : null;
                this.family_thumb = now.thumb ? now.thumb : null;


                setTimeout(function () {
                  self.refresh();
                }, 60000);

              }
          );


    },
    refreshSong: function () {


      let self = this;

      axios2
          .get('index/song')
          .then(response => {

                let data = response.data;

                this.song_name = data.song;
                this.song_artist = data.artist;

                setTimeout(function () {
                  self.refreshSong();
                }, 60000);

              }
          );

    }
  },
  created() {
    this.refresh();
    this.refreshSong();
  }
}
</script>

<style scoped lang="scss">
.roadmap-now {
  //background-color: rgba(0, 0, 0, .3);

  display: flex;
  flex-direction: row;

  //padding: 6px;
  margin: 12px 0;

  border-radius: 6px;

  overflow: hidden;
  max-width: 60px;
  width: 60px;
  height: 63px;
  max-height: 63px;

  &__thumb {
    margin-right: 12px;

    &-img {
      width: 52px;
      height: 52px;

      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 6px;
    }

    display: none;
  }

  &__family {

  }
  &__song {

    font-size: smaller;
    opacity: .7;

    max-width: 150px;
    width: 150px;
    height: 20px;

    display: inline-block;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    //font-weight: bold;
    font-style: italic;

  }
  &__info {
    padding: 6px 0 0;
    display: none;
  }

  &__play {
    padding: 0;
    //margin: -6px -6px -6px 0;
    position: relative;
    width: 60px;
    min-width: 60px;

    border-radius: 50%;

    background-color: #1f4bf5;

    & img {

      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      position: absolute;

      width: 30px;

    }
    & i {
      padding: 18px;
    }
  }

}
</style>
<template>


  <div class="podcast-block" v-if="article">
    <div :to="'/'+article.url" class="podcast-block__thumb"
                 :style="{ backgroundImage: `url(${article.block_thumb})` }"></div>
    <div class="podcast-block__play">

      <a :href="article.audio_url" v-if="article.audio_url"
         :data-name="article.name"
         :data-thumb="article.block_thumb"
         :data-url="article.audio_url"
         :id="'audio'+article.id"
         class="podcast-block__play-btn audio-btn"

         v-on:click="playAudio"
      >
        <i class="material-icons">play_arrow</i>
      </a>
    </div>
    <div class="podcast-block__info">
      <h3 class="podcast-block__title">
        <router-link :to="'/'+article.url">
          {{ article.title }}
        </router-link>
      </h3>
    </div>
  </div>

</template>

<script>
import axios from "@/axios2";
import CoreDate from "@/helpers/date";

export default {
  name: "PodcastBlock",
  components: {},
  props: {'article': null},

  methods: {

    playAudio: function (e) {

      e.preventDefault();

      let audioPlayer = this.$root.$refs.audioPlayer;
      let article = this.article;

      console.log(article.audio_url)

      audioPlayer.set(article.audio_url, article.name, article.block_thumb);
      audioPlayer.play();
      audioPlayer.show();

    },
  },
  computed: {
    date() {
      return CoreDate.show(this.article.date)
    }
  },

  data() {
    return {}
  },
  created() {


    // let domain = "audycje"
    // let domain_new = "podcasty"


    // this.article.url = domain_new + this.article.url.substr(domain.length)

  }

}
</script>

<style scoped lang="scss">
.podcast-block {
  position: relative;

  margin-bottom: 18px;
  min-height: 90px;

  //border: 1px solid #eee;
  padding: 0;

  overflow: hidden;
  background-color: #fff;

  width: 100%;
  height: 0;

  padding-bottom: 750px;
  border-radius: 8px;


  &__play{
    width: 60px;
    height: 60px;
    position: absolute;

    //left: 50%;
    //top: 50%;
    //transform: translateX(-50%)  translateY(-50%);
    right: 50px;
    bottom: 30px;
    //transform: translateX(-50%)  translateY(-50%);

    z-index: 3;
    @media screen and (max-width: 720px){
      left: 50%;
      top: 50%;
      transform: translateX(-50%)  translateY(-50%);
    }

  }
  &__play &__play-btn {

    width: 60px;
    height: 60px;

    background-color: #1d4bfe;
    color: #fff;

    text-align: center;

    display: inline-block;
    border-radius: 50%;

    i {
      line-height: 58px;

    }

  }
  &__thumb {



    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    display: block;

    position: absolute;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {


    position: absolute;
    left: 30px;
    bottom: 6px;
    //line-height: 20px;
    font-size: 32px !important;
    font-weight: bold;
    //white-space: nowrap;
    font-family: "Code-Pro", sans-serif;;
    color: white;
    text-shadow: 1px 1px 10px #000;

    //overflow: hidden;
    //text-overflow: ellipsis;

    text-align: left;

    @media screen and (max-width: 720px){
      font-size: 24px!important;
    }



    a {
      color: #fff;
    }


  }

}



</style>

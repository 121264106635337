<template>

  <ul class="right hide-on-med-and-down first">

    <MenuPosition type="small"/>
    <li>
      <RoadmapNow/>
    </li>

    <ul class="menu-btns hide-on-large-only hide-on-extra-large-only right">
      <li class="menu-btns__item">
        <a
            class="sidenav-trigger menu-btns__link white-text"
            @click="menuMobileOpen"

        >
          <i class="material-icons">menu</i>
        </a>
      </li>
    </ul>

  </ul>
</template>

<script>
import MenuPosition from "./MenuPosition";
import RoadmapNow from "./RoadmapNow";

export default {
  name: "MenuTop",
  components: {RoadmapNow, MenuPosition},
  methods: {
    menuMobileOpen(e) {

      e.preventDefault();

      this.$root.$refs.menuMobile.open();

    }
  }
}
</script>

<style scoped lang="scss">

</style>
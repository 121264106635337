<template>

</template>

<script>

import ComponentStateMachine from "@/Extends/ComponentStateMachine";

/**
 * Stan kompunentu gdy oczekuje na akcje użytkownika
 *
 * @type {string}
 */
const list_state_waiting = 'waiting';

/**
 * Stan kompunentu gdy oczekuje na odpowiedź z serwera
 * @type {string}
 */
const list_state_loading = 'loading';

/**
 * Stan kompunentu gdy serwer zwrócił błąd
 * @type {string}
 */
const list_state_error = 'error';

/**
 * Stan kompunentu gdy serwer zwrócił sukces
 * @type {string}
 */
const list_state_success = 'success';





export default {
  name: "ComponentListStateMachine",
  extends: ComponentStateMachine,
  data() {
    return {
      errorMessage: "",
      states: {
        list_state_waiting: list_state_waiting,
        list_state_loading: list_state_loading,
        list_state_error: list_state_error,
        list_state_success: list_state_success,
      },

      state: list_state_waiting,
      state_transitions: {
        [list_state_waiting]: {
          'sendInfo': list_state_loading
        },
        [list_state_loading]: {
          'sendSuccess': list_state_success,
          'sendWrong': list_state_error
        },
        [list_state_error]: {
          'sendInfo': list_state_loading
        },
        [list_state_success]: {
          'sendInfo': list_state_loading
        }
      }

    }
  },


}
</script>

<style scoped lang="scss">


</style>
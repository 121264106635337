<template>

  <main class="contact">


      <div class="row">
        <div class="col s12">
          <h1 class="big-title">
            <router-link to="/zespol" title="Zespół" style="color: #000000">
              Zespół
            </router-link>
          </h1>
        </div>
      </div>

      <div class="article-user-section" :class="{loading: stateCompare('loading')}">
        <carousel v-if="users && users.length > 0" :settings="settings" :breakpoints="breakpoints">
          <slide v-for="user in users" :key="user.id">
            <PeopleLine :user="user"/>
          </slide>

          <template #addons>
            <Navigation/>
          </template>
        </carousel>

      </div>


  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import PeopleLine from "@/views/People/Part/Line.vue";
import CoreArray from "@/helpers/array.js";
import {Carousel, Navigation, Slide} from "vue3-carousel";
import axios from "@/axios2";
import ComponentListStateMachine from "../../../Extends/ComponentListStateMachine";

export default {
  name: "PeopleOnMain",
  extends: ComponentListStateMachine,
  components: {
    Carousel,
    Slide,
    Navigation,
    PeopleLine,
  },
  data() {
    return {
      'count': null,
      'users': null,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1224: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },

    }
  },
  methods: {
    refresh: function () {

      this.stateUpdate('sendInfo');

      axios
          .get('user/get', {
            params: {
              sort: ['priority DESC','lastname ASC'],
              page: this.page,
              count: 40,
              show_on_list: "yes",
              role: "editor",
              scope: 'firstname,lastname,avatar_url,url_full,position'
            }
          })
          .then(response => {

            let arr = response.data.data;
            let column = "avatar_url";
            // let from = "https://off.radiokrakow.pl/api/";
            // let to = "https://off.radiokrakow.pl/";

            // arr = CoreArray.changeDomain(arr,column,from,to);

            arr = CoreArray.changeDomain(arr,'url_full','ludzie-radia/','zespol/');

            this.users = arr;
            this.count = response.data.count;

            this.stateUpdate('sendSuccess');

          })
          .catch(error => {
            this.refresh();

            this.stateUpdate('sendWrong');

          });


    }
  },
  watch: {
    page: function (val) {
      this.refresh();
    },
  },
  created() {
    this.refresh();
  }
}
</script>


<style>


.article-user-section .carousel {
  height: auto;
  overflow: visible !important;
}

.article-user-section .carousel__viewport {
  overflow-y: visible !important;

}

.article-user-section .carousel__slide {
  text-align: left;
  padding: 0 12px;
}

.article-user-section .carousel__slide .family-block {
  width: 100%;
}

.article-user-section .carousel__prev,
.article-user-section .carousel__next {

  top: -33px !important;
  background-color: transparent;
  position: absolute;
  color: #1d4bfe;

  display: block;
  left: auto;

  /*background-color: red!important;*/

  z-index: 200000;
}

.article-user-section .carousel__prev {
  right: 45px !important;
}

.article-user-section .carousel__next {
  right: 25px !important;
}

</style>

<template>
  <section class="article-section">


    <div class="container-fluid">
      <div class="row">
        <div class="col s12">
          <div class="title-line mb-3">
            <h2 v-if="url">
              <router-link :to="url" class="title-line__link">

                {{ name }}
              </router-link>
            </h2>
            <h2 v-if="!url">
              {{ name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col s12 m6 l4 xl3" v-for="article in articles">

          <PodcastBlock :article="article"/>

        </div>


      </div>
    </div>

  </section>
</template>

<script>
import axios from "@/axios2";
import {Carousel, Slide, Pagination} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import PodcastBlock from "@/views/Audio/Part/PodcastBlock";
import CoreArray from "@/helpers/array";

export default {
  name: "PodcastSection",
  props: ['code'],
  components: {
    PodcastBlock,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      articles: null,
      name: null,
      url: null,

    }
  },

  created: function () {

    let domain = "https://off.radiokrakow.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    axios
        .get('articlesection/find', {
          params: {
            code: this.code,
            scope: 'name,url,articles',
            articles_scope: 'title,block_thumb,thumb,date,url,audio,audio_url_force,block_title,block_thumb',
          }
        })
        .then(response => {

          let data = response.data;

          this.name = data.data.name;
          this.url = data.data.url;

          let articles = data.articles;
          articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');

          // articles = CoreArray.changeDomain(articles, 'thumb', domain, domain_new);
          // articles = CoreArray.changeDomain(articles, 'block_thumb', domain, domain_new);
          // articles = CoreArray.changeDomain(articles, 'audio_url', domain, domain_new);

          this.articles = articles;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>


</style>

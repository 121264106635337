//Mylib.js

let exports = {};

/**
 * Sortuje tablice po określonej kolumnie
 *
 * @param arr tablica danych
 * @param column nazwa systemowa kolumny
 * @param type czy sortować w górę czy w dół
 * @param propertyType jaki format danych znajduje się w kolumni
 *
 * @returns {*} tablica danych
 */
exports.sort = function (arr, column, type = 'ASC', propertyType = 'string') {

    let sort_type = propertyType.toLowerCase()+' '+type.toLowerCase()

    switch (sort_type) {
        case 'date asc':
            arr.sort((a, b) => (new Date(b[column])) - (new Date(a[column])));
            break;
        case 'date desc':
            arr.sort((a, b) => (new Date(a[column])) - (new Date(b[column])));
            break;
        case 'string asc':
            arr.sort((a, b) => ((a[column].trim() > b[column].trim()) ? 1 : (a[column].trim() < b[column].trim()) ? -1 : 0));
            break;
        case 'string desc':
            arr.sort((a, b) => ((a[column].trim() < b[column].trim()) ? 1 : (a[column].trim() > b[column].trim()) ? -1 : 0));
            break;
    }



    return arr;

}

exports.arrayFromObject = function (arr){

    let ret = [];


    let size = Object.keys(arr).length;
    let keys = Object.keys(arr);
    let values = Object.values(arr);

    for (let i = 0; i < size; i++) {
        ret[keys[i]] = values[i];
    }

    return ret;
}

exports.changeDomain = function (arr, column, from, to){

    arr.map(function (val){
        if (val[column])
        val[column] = to + val[column].substr(from.length)
    })

    return arr;

}

export default exports;
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import "./assets/css/materialize.css"
import "./assets/css/mod.css"
// import "./assets/css/model.css"
import "./assets/css/style.css"

createApp(App).use(store).use(router).mount('#app')

//Mylib.js
let exports = {};

/**
 * Porównuje dwie daty ale tylko rok, miesiąc i dzień
 *
 * @param d1 pierwsza data
 * @param d2 druga data
 *
 * @returns {boolean} tru jeśli datysą równe
 */
exports.dateEqual = function (d1, d2) {

    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();

}

/**
 * Zwraca wczorajszą datę
 *
 * @returns {Date} wczorajsza data
 */
exports.dayAgo = function (date) {

    let seconds = Math.floor((new Date() - new Date(date)) / 1000);

    let interval = seconds / 86400;

    return Math.floor(interval);
}

/**
 * Zwraca wczorajszą datę
 *
 * @returns {Date} wczorajsza data
 */
exports.yesterday = function () {

    return new Date(new Date().setDate(new Date().getDate() - 1));

}

/**
 * Zwraca datę jaka była przed wczoraj
 *
 * @returns {Date} data przedwczoraj
 */
exports.beforeYesterday = function () {

    return new Date(new Date().setDate(new Date().getDate() - 2));

}

/**
 * Zwraca jutrzejszą datę
 *
 * @returns {Date} data jutrzejsza
 */
exports.tomorow = function () {

    return new Date(new Date().setDate(new Date().getDate() + 1));

}

/**
 * Zwraca datę jaka będzie po jutrze
 *
 * @returns {Date} data po jutrze
 */
exports.afterTomorow = function () {

    return new Date(new Date().setDate(new Date().getDate() + 1));

}

/**
 * Sprawdza czy data to dzisiaj
 *
 * @param date sprawdzana data
 */
exports.isToday = function (date) {
    let d = new Date();
    let td = new Date(date);

    return exports.dateEqual(d, td);

}

/**
 * Sprawdza czy podana data jest datą wczorajszą
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data wczorajsza
 */
exports.isYesterday = function (date) {

    let d = exports.yesterday();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}

/**
 * Sprawdza czy podana data jest datą z przed wczoraj
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data z przed wczoraj
 */
exports.isBeforeYesterday = function (date) {

    let d = exports.beforeYesterday();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}


/**
 * Sprawdza czy podana data jest datą jutrzejszą
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data jutrzejsza
 */
exports.isTomorow = function (date) {

    let d = exports.tomorow();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}


/**
 * Sprawdza czy podana data jest datą z po jutrza
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data z po jutrza
 */
exports.isAfterTomorow = function (date) {

    let d = exports.afterTomorow();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}

/**
 * Zwraca datę w formie do wyświetlenia
 *
 * @param date obiekt daty
 *
 * @returns {string} data do wyświetlenia
 */
exports.show = (date, type = 'smart') => {

    let ret = null;

    switch (type) {
        case "smart":
            // if(exports.isBeforeYesterday(date)){
            //     ret = 'Przed Wczoraj';
            // }

            if (exports.isYesterday(date)) {
                ret = 'Wczoraj';
            } else if (exports.isToday(date)) {
                ret = 'Dzisiaj';
            } else if (exports.isTomorow(date)) {
                ret = 'Jutro';
            }
            // else if( exports.dayAgo(date) < 7){
            //     let date_obj = new Date(date);  // 2009-11-10
            //
            //     ret = date_obj.toLocaleString('default', {weekday: 'long'});
            //
            //
            // }
                // else if(exports.isAfterTomorow(date)){
                //     ret = 'Pojutrze';
            // }
            else {
                let date_obj = new Date(date);  // 2009-11-10

                let day_week = date_obj.toLocaleString('default', {weekday: 'long'});

                let month_numeric = date_obj.toLocaleString('default', {month: 'numeric'});
                let day = date_obj.getDate();
                let year = date_obj.getFullYear();

                console.log(month_numeric);

                let month = '';
                switch (parseInt(month_numeric)) {
                    case 1:
                        month = 'stycznia';
                        break;
                    case 2:
                        month = 'lutego';
                        break;
                    case 3:
                        month = 'marca';
                        break;
                    case 4:
                        month = 'kwietnia';
                        break;
                    case 5:
                        month = 'maja';
                        break;
                    case 6:
                        month = 'czerwca';
                        break;
                    case 7:
                        month = 'lipca';
                        break;
                    case 8:
                        month = 'sierpnia';
                        break;
                    case 9:
                        month = 'września';
                        break;
                    case 10:
                        month = 'października';
                        break;
                    case 11:
                        month = 'listopada';
                        break;
                    case 12:
                        month = 'grudnia';
                        break;
                }

                ret = day_week+', '+day + ' ' + month + ' ' + year;

            }
            break;
        case "date":
            let date_obj = new Date(date);  // 2009-11-10

            let month = date_obj.toLocaleString('default', {month: 'short'});
            let day = date_obj.getDate();


            ret = day + ' ' + month;
            break;
    }

    return ret;

};

exports.showWeekDay = (date) => {

    let date_obj = new Date(date);  // 2009-11-10

    return date_obj.toLocaleDateString('default', {weekday: 'short'});
}


export default exports;
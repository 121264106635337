import {createStore} from 'vuex'

export default createStore({
    state: {
        'radio_play': false
    },
    mutations: {
        radio_play(state, radio_play) {

            state.radio_play = radio_play;
        }
    },
    actions: {


        async radioPlay({commit}) {

            commit('radio_play', true);

        },
        async radioStop({commit}) {

            commit('radio_play', false);

        },
    },
    modules: {}
})

<template>
    <li v-if="type === 'full'">
        <router-link to="/jak-nas-sluchac" @click.prevent="$root.$refs.menuMobile.close()">
            Jak nas słuchać
        </router-link>
    </li>
    <li v-if="type === 'full'">
        <router-link to="/misja" @click.prevent="$root.$refs.menuMobile.close()">
            Misja
        </router-link>
    </li>

    <li>
        <router-link to="/ramowka" @click.prevent="$root.$refs.menuMobile.close()">
            Ramówka
        </router-link>
    </li>

    <li>
        <router-link to="/rekomendacje" @click.prevent="$root.$refs.menuMobile.close()">
            Rekomendacje
        </router-link>
    </li>


    <li>
        <router-link to="/audycje" @click.prevent="$root.$refs.menuMobile.close()">
            Audycje
        </router-link>
    </li>

    <li>
        <router-link to="/podcasty" @click.prevent="$root.$refs.menuMobile.close()">
            Podcasty
        </router-link>
    </li>

    <li>
        <router-link to="/playlist" @click.prevent="$root.$refs.menuMobile.close()">
            Playlista
        </router-link>
    </li>

    <li>
        <router-link to="/zespol" @click.prevent="$root.$refs.menuMobile.close()">
            Zespół
        </router-link>
    </li>


    <li style="display: none;">
        <router-link to="/contact" @click.prevent="$root.$refs.menuMobile.close()">
            Kontakt
        </router-link>
    </li>

</template>

<script>
export default {
    name: "MenuPosition",
    props: {
        type: {
            default() {
                return "full";
            }
        },
    },
    methods: {}
}
</script>

<style scoped lang="scss">

</style>
